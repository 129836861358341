.audioContainer {
  position: relative;
  width: 50px;
  height: 50px;
}

.audioImage {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
}
