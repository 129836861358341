.open-in-spotify-button {
    background-color: #1DB954; /* Spotify brand color */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
  }
  
  .open-in-spotify-button:hover,
  .open-in-spotify-button:focus {
    background-color: #1ed760;
  }
  